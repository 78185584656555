import store from "../store";
import { checkAccountInWhitelist } from "./dataBackend"
var Web3 = require('web3');


const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchGeneralData = () => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());

    try {
      const web3 = new Web3("https://mainnet.infura.io/v3/06fe0445755547ada4623b41ef2428ba");
      const abiResponse = await fetch("/config/abi.json", { headers: { "Content-Type": "application/json", Accept: "application/json" } });
      const abi = await abiResponse.json();
      const configResponse = await fetch("/config/config.json", { headers: { "Content-Type": "application/json", Accept: "application/json" } });
      const CONFIG = await configResponse.json();
      const contractInstance = new web3.eth.Contract(abi, CONFIG.CONTRACT_ADDRESS);
      const totalSupply = await contractInstance.methods.totalSupply().call();
      const maxSupply = await contractInstance.methods.maxSupply().call();

      dispatch(fetchDataSuccess({ totalSupply, maxSupply }));
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};

export const fetchData = async (account) => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());

    try {

      let totalSupply = await store.getState().blockchain.smartContract.methods.totalSupply().call();
      let maxSupply = await store.getState().blockchain.smartContract.methods.maxSupply().call();
      let cost = await store.getState().blockchain.smartContract.methods.cost().call();
      let nftMinted = await store.getState().blockchain.smartContract.methods.addressMintedBalance(account).call();
      let hexProofOG = await checkAccountInWhitelist(account, 0);
      let hexProofWL = await checkAccountInWhitelist(account, 1);
      let hexProofWHALE = await checkAccountInWhitelist(account, 2);
      let amountPUBLIC = Number(await store.getState().blockchain.smartContract.methods.amountPUBLIC().call())
      let amountOG = Number(await store.getState().blockchain.smartContract.methods.amountOG().call())
      let amountWL = Number(await store.getState().blockchain.smartContract.methods.amountWL().call())
      let amountWHALE = Number(await store.getState().blockchain.smartContract.methods.amountWHALE().call())
      let isOG = hexProofOG ? true : false
      let isWL = hexProofWL ? true : false
      let isWHALE = hexProofWHALE ? true : false

      let freeMintable = 0
      let higherProof = []
      if (await isOwner(account)) {
        freeMintable = 20
      } else if (isWHALE) {
        freeMintable = amountWHALE > nftMinted ? amountWHALE - nftMinted : 0
        higherProof = hexProofWHALE
      } else if (isOG) {
        freeMintable = amountOG > nftMinted ? amountOG - nftMinted : 0
        higherProof = hexProofOG
      } else if (isWL) {
        freeMintable = amountWL > nftMinted ? amountWL - nftMinted : 0
        higherProof = hexProofWL
      } else {
        freeMintable = amountPUBLIC > nftMinted ? amountPUBLIC - nftMinted : 0
      }

      /*
      console.log("freeMintable: " + freeMintable)
      console.log("isOG: " + isOG)
      console.log("isWL: " + isWL)
      console.log("isWHALE: " + isWHALE)
      console.log("amountWL: " + amountWL)
      console.log("amountOG: " + amountOG)
      console.log("nftMinted: " + nftMinted)
      console.log("amountOG > nftMinted " + (amountOG > nftMinted))
      console.log("amountOG - nftMinted: " + (amountOG - nftMinted))
      */

      dispatch(
        fetchDataSuccess({
          totalSupply,
          maxSupply,
          nftMinted,
          isWL,
          isOG,
          isWHALE,
          freeMintable,
          higherProof,
          cost
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};

async function isOwner(account) {
  const configResponse = await fetch("/config/config.json", { headers: { "Content-Type": "application/json", Accept: "application/json" } });
  const CONFIG = await configResponse.json();
  return account.toLowerCase() == CONFIG.OWNER.toLowerCase()
}