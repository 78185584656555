const initialState = {
  loading: false,
  totalSupply: 0,
  maxSupply: 0,
  ogMinted: 0,
  wlMinted: 0,
  hexProofOG: 0,
  hexProofWL: 0,
  amountOG: 0,
  amountWL: 0,
  cost: 0,
  error: false,
  errorMsg: "",
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHECK_DATA_REQUEST":
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_SUCCESS":
      return {
        ...state,
        loading: false,
        totalSupply: action.payload.totalSupply,
        maxSupply: action.payload.maxSupply,
        nftMinted: action.payload.nftMinted,
        isWL: action.payload.isWL,
        isOG: action.payload.isOG,
        isWHALE: action.payload.isWHALE,
        freeMintable: action.payload.freeMintable,
        higherProof: action.payload.higherProof,
        cost: action.payload.cost,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_FAILED":
      return {
        ...initialState,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default dataReducer;
