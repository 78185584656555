const { MerkleTree } = require('merkletreejs');
const keccak256 = require('keccak256');

let whitelistAddressesOG = [
    "0x68e4b1A3d7315F27a78550e5CDa86fB27cbe007A",
    "0xab1417494c348a8defbe7a0d5e7b4547da286c92",
    "0x2D3fBd62A90Dd58C5606975b963498739ebC2CEd",
    "0x995F625E36B17170Bd946F9610d07A6666A7ee7c",
    "0xae4b25d333ffadd4959658d00c8fe857d5be396c",
    "0x5e10cb1b0e8f673f98a5a5a4001a375fa1cb6748",
    "0x5F144158970B53f6dCBB761e7B02B6455d05C861",
    "0xfEF805a586580a845440ea303324bfDd2FC8AcC0",
    "0x545582D94B338bC445e906a3c9877Ed2DFf19E90",
    "0x6F5f2271e70A98409B8c091291803Bb894E8aa7d",
    "0xc2e78371789CC67DF6f18774ca4A35882044C1E5",
    "0x72CcD287CAf23D839ab9760EE3C55DC4Db55321A",
    "0xcd21d3b0e8fb11287927c7cffd3e329fcc7e17aa",
    "0x1AC0FA9Ab44b19d495065AAc4931e3E92C3305D9",
    "0x6eB1a4236fBf25a1E7fFF8985D5FD813C5eaCB1a",
    "0x3AB66471fBA1804AA8b95abf0b0f8Eb2aD0FD7d8",
    "0xBfdC89253c41DA7b3705ad478F78D2c810A9657B",
    "0xc8e27bd06e6B9B31e716fA23681AfBadD75C1f5f",
    "0x2Fda6b369677BC0637B27b276125683359d1C995",
    "0x5B22579045aa510d979766A47E58C78453EF13EC",
    "0xB7b2a7cB1f3E0E23aca9A6ca39372A53f2560211",
    "0x49548D3Ba62403F26bEC711886704E1205a45fd8",
    "0xE77262fB26c5FF7De2C56f4e016C4CdB06e1779e",
    "0xBDB969A121D3Bd526D90996D426e815C1e88652B",
    "0x97D4d56c8f1eEc783CC5B303CbE7D5b501E6Eb79",
    "0x117972f869f12fd5Bd31622eEB12e166ACAfB6c8",
    "0x426A0B4DF2ddff42A52f51032Cd48dA62B489ebb",
    "0x2230F9D381721A73a88809C3375299FE996C3B34",
    "0x4774730Fd1A81029d8AE9ebF9bcCb6db31b46a5B",
    "0xBAdFd1949a51b18B35d28b8bd8BfC333205b4Dd0",
    "0x4D0cc05daD3Eb7D10a4961d4AedDFD60c3076C6e",
    "0xB33D68146533A4A21d98e8E9b4d69803E1CE05C4",
    "0x8853F1e3Bfa863dc03De52155db2F23AD384a6D0",
    "0xf83733F9fEBc2b06a8CEc34d9EA26bBd7cA05569",
    "0x5afb33754273eEd333b071C4055757A490c7dBa4",
    "0x817682851004Dbe5b860D641633Be7E8b4949c64",
    "0xB1e2526B4a88f6bCF731Fa6A288558c13af797f2",
    "0x01b52c5b5c893d822a0b8e515a7709ca5d900b5f",
    "0x73156fbd3e92ecb18cf202c20e05f8c23e8bbc4d",
    "0xdBd5A41581F06FA33cE5E9b8Fe0D546D2A3bA6D1",
    "0xF96fd6F445dc6B20af87BD805c362E3b503eBC49",
    "0xED72476C07d31e1F4383b789606D367cbD1Eb7F5",
    "0xD958D165957A20A2D3019F6078c48f4FC628c6D0",
    "0xd6c9961b2658198035dd4ed1d85dC10C58629127",
    "0xef9457C120eA04Ec66801F5554a71200326C1631",
    "0x9808231271124715119C535BD31838de71661F03",
    "0xFd2a795666Afb2266Eb2136a74D1a95E0535F259",
    "0xDa4bBC4014076E0B37B5E8b946feF74F333128AF",
    "0xA9171FC7B7Df76Ec09e0D94d46866c1A7831Bf10",
    "0x381B730eB1Aea34AB53F56b4b0bc7f9C251D965B",
    "0xDC585f2774a154fef87EAa666d1ED2bBc187947F",
    "0x091d8F6e2570bB77d2623E390D28278BE4BbD218",
    "0x4f765b9FCD43712404dC32CeE0bd4cAdf073CC05",
    "0x514af73E062dFE28901Abf26C478e0f3E3ad03dA",
    "0x4c8E22d1c15Ed6AD0fE36e6d5D7fc80e0Fc00b38",
    "0x798c4D95F5C186ea4fA2838CDa5e797A28f982E6",
    "0x7eA34bAb4F317596DC068fEF7c6030197cb650E8",
    "0xb8F7196463a52741d16EDf7B66738C1EA0eE7966",
    "0x4f2f19F64A5891115BCDad2446FEEBF2E82eb6C8",
    "0x38031F1493064646bE7d4670aA90588255c3a3C8",
    "0xc9579CAAcf1c83756450cF5F20D4040F433db562",
    "0xDC706A6c1466bEf76E727b3e30349e51d23Bf79b",
    "0x3b0D5d308e160c0aE05E9e6e1cAe8a36C4462eD2",
    "0xB93a7932C807e8adF115C58b4D7825b0572bFe1E",
    "0xd5c86C2e4Dd07FCE5354AFF248A408B686d6fD60",
    "0xb1ADD88461eC550e0C6DFb3CAc7654512F69BbE8",
    "0xB89bFcD5C4F5bcD081989bab7963D7eC01400CaF",
    "0x7b39291665de458894Fc1ca0D316d642FE567f7e",
    "0x434Eb950eF67470F1c255BDAcd1b6A39e1c67A14",
    "0x9308E6be6373B9d0593B23935f0019c1c46B6168",
    "0xBb1f78fe4729eFe133825f87DBbB247CDB3AC0C0",
    "0x77B2F9a5DF2efc260B6f1b57C636f64C3b3e926F",
    "0xc0717cb3AFC07e38C1ae67acc5f397dA8FB7eFaE",
    "0x666635C982276c2773f4F53978b56EEe42491498",
    "0x25389037d62DA127C7FeaeaB5987e0f5cA5fA60A",
    "0x414480e92394FBbab283e1174aBe5F7b16355514",
    "0x2e00846592959FE73C57d57860CC9D175744711e",
    "0xA3069CFA9C0aA758965E29285b24090e816e2308",
    "0x72663d91773b7ee255822322a99aa2860ff3a85a",
    "0xad431E740Bb07848a85415df29541ECFe2F62C84",
    "0x183DfBF69C1e6D76931288F5614Cb2214b12bB6b",
    "0x5Ecd6259beD9Bef30925fEd4eE68B2190EaF5eB4",
    "0x5bc6F0662134D59d5eD145672C3137e1f3bCFd36",
    "0x68696D2d051005c195eb07EFF091457F6B2D21ED",
    "0x57CC7a82776aC275ace75C900e6A7126cbb8c29F",
    "0xa8467Ce68F4c2aF9C0B21E5f9A140eb691c90768",
    "0xf679eD02bC523f78473e8660A617f3c16127dbe2",
    "0x6D9916fc54A27B8F936cCb3480CC6FBbbE7cb23d",
    "0xCeA6d4bAaf2155859c155D3A3CC92842c1508a96",
    "0x248610bcf5b309886890d253BCd32B3cc25aF786",
    "0x0431de6d4c9ed5712a9df217c7d2f5f7e670d6fa",
    "0xE33BDe7E83D648D30B7399A3F8843D9EC11d151F",
    "0x378a74911747e140aFFB121318D63E70bdf30C35",
    "0x3537477a12A098be61Ecb7eCE34719B0C162139B",
    "0x8f32aE0b4180C5320aAee0aBe8005a07BE941F46",
    "0x50611CC468a1a02f931c807417F57EE55898E496",
    "0xC99e255f88E39889A5FeC0B8D58A2Dba426BEcd8",
    "0x4132C0C09D51e9d85599291648f6a81357F7D821",
    "0x3fc36aDE615BF6e4581A6CF91B80fD46165cbdBA",
    "0xD41CEEDfBE5ccd671199f250F4eCE52AFFfC3aFc",
    "0x2cD795a235Fb1C0cdc80dD8975bA69688858a285",
    "0xc6473856a74732374ec3aF4f2B04cb94A28f60B1",
    "0xD89d37B9b1058f5B5D49ebFd067b269087Aee7e0",
    "0xb4b2Ce2d75B9D7379f93f8dD4D42Ba015C8F7ea4",
    "0x69996A1C2cF3e94c5b2856A4Df1089D3eAfB1660",
    "0x1e7CE36DA0bA4821a36582c407A654D214DbCFC3",
    "0x435F3e8B4ce25aEA096F423fc61BC334B197e019",
    "0x6B0e9E027abc8BAaaC1761cE515C232A111129f6",
    "0xB00D57CB6c332d62CcB670B2E61DBAb917A92D51",
    "0x0f2D050d7c0926a50B3c4C6c72A5412812C79BD4",
    "0x8Cf61E409b9B90C1Dfa12D35810F7711371AB68A",
    "0x35A2ab7Aa5089c860B51c9ED148beB79F2835b93",
    "0xc756B2681E1fD93ec3dbF114E36975F82BD103eb",
    "0x30aA851F3905deD4eB7a3680FC2809Aee7f47D07",
    "0x2395E61Be7Aa1A2823F7c85bF8D6cdE348760F84",
    "0x87F3770D0E364419Ca0307b2fE53bbF3E6089B09",
    "0x8E0EDD0fd4CD8A8ABbd77487469769Cf4888B181",
    "0x26712645ebF56AfaD0Fb877e3D2109E02106F074",
    "0x7D3dc2547f507Df0cfe704e6D17E6A8541e6f36d",
    "0x3869E8e565d2FF80817439c680DEbb937947cAc6",
    "0x312271094ac441BF566184a37a7fA5ac9e397902",
    "0x5670C9aE19Cdb966dFE91C280f1D3FdC6999923e",
    "0xc4f5be6b7951b154aa73115f700604eeb2499ff3",
    "0xFbECf6BEF97f1DdfD74195D52dbBe25CC7c086b0",
    "0xb7f06401A2954d2AFb3e1380Ee0dD8Fe3726471D",
    "0xc2484fBFb220D093AA7DA5BEc09caD2E403AcdF6",
    "0x02F75Cc5E776A241E7b5738f24b61D222B62E404",
    "0xC60517F48c10C98160A5a2383aAb9C6606cEb624",
    "0xab25D9a34B87D30098e090b8AC608acB8AB98341",
    "0x69e9541Bae37fd07cFfcf4Bb9f1816B0121cdeeC",
    "0x5B38dC8dfa9a171aB80b6AD06E6E57f9177a0432",
    "0x0828d7eCD3B5D6461Af19A9B21bB9F9FE2Dea43E",
    "0x1523D8E9f6C6d6Af3Ebc3b75E5503f47d57C0504",
    "0xE4EA824CD80E61E101Af246E9B217F155Ee2E090",
    "0xf727F8db63569104C8D9FBf38B761cFA80aeb516",
    "0x60DB1B023cc89AE61BCe47c470aFF4fFBF674e35",
    "0x7c947ccE5f01F2b5De341266b30D40811b094944",
    "0xeD9393f4CbE273D94002975aB9D73420c92F710a",
    "0x1ee5c1DCf49433BE8696c085c5edF7a1aD467E7a",
    "0x243AFdeC8f104119Eb409ca776C9C2f4941f5307",
    "0xf055E89033b00Be0Eb46f8302fb106449CFe5AD2",
    "0xfE3Cf487565A3Cd275cb2cbf96a395F023637D86",
    "0x31ec5BE3Dba53012280B85243BBaB558c41b163c",
    "0xFeC236Fb18250fD9880f280601ba15A9F47bE7F5",
    "0x52eB606f94091A2BA4f985D98C62880a9BA591B6",
    "0xdf1AfB318bEF8Cf3047dF6d65177145B01E58c92",
    "0x2d80B1d4009C47FB257216725Ff9478C65BDd259",
    "0x4f4E61b556300fbe349adF08a722eeE081643f48",
    "0x24313F0495e0Db6212ef9CA143ca9a94e39CC700",
    "0xC7d8a6955859d9903c63216C8345A3DAdC8d146f",
    "0x21237Fd9034b76fDDC9d213f8AB38010ceE4Ac75",
    "0x5638de887060aaD096b9c8C6E93f16292e429471",
    "0x7F88df6ac16e5ee4A75B98F4842cA2c1E7EA88cD",
    "0x6c3850F04b48817557aFAB3c79015c3E86D6D0E5",
    "0xB67A054572a8fD44C86b4e563d4ff820c257B9CA",
    "0x1Bc9E3c3C410C41a2D784107a4fC89FE1378F441",
    "0x472041c922dF8663e071932C6bB7dd3978C63109",
    "0x4F0F9F2DF0B2149a364D6958B4F6597F85C66C32",
    "0x2518d14b6797D5e974e9bF6B1345B3f7970a3954",
    "0x17b319479497019A0AeB39c3E1E7ECfc5DC8B94A",
    "0x23438ff96eaa034fEDD1EFFE4dC0616ddC3525c2",
    "0xF3c7Fc54F15fb4a8DC9D6E111dCD3B0c24d416d4",
    "0x4012dBd303447EC2EF495f18c1f95CCf01a33594",
    "0xf5b8231BF3792b7080e705a0B162b65cB9902C87",
    "0x6d9f24aFC7e0C2a102124B769bd9E8DfE25f303c",
    "0x600A889107f8a7121aa71D5CF93BC24499a64b48",
    "0x42b4F5e916eC0fD4b01F4D01536cAc180091fE1E",
    "0x8FBe84F3Fefc0A9411bF87A216875110468E4910",
    "0xB050FdE04c1E5aaed62f94F7aC14d5Ed9b166057",
    "0x70e58Ef239dA7DA1D5332C34FAD21e636EF483Cf",
    "0xe1cDF32847258e5872dcFbB27958A64DA3327117",
    "0xDA97b3b43DB3501a220b713A9f4F2D359C722915",
    "0x9Edc8F80B08B223617E1385561f5c8be4Aa1a73b",
    "0xf8648D32b882E97C44EFB05824cf3EaD87DF729f",
    "0x67B248b88077b49a8aF84c40fB89A22E8E06074f",
    "0x77bdd60a3537e1e1367D9114a1b7017753173A22",
    "0x82fBC709258990cCE657ba61236e26cDDB2D7A47",
    "0x5F1F54D347e5Cb097bf83d053EbBf5E34F1CA65b",
    "0xa84dd0c6a8f5DEA65672f1B82DAFb79b3d0Db748",
    "0xCF5c36072602b8519427CFf5bcDDa69EbDe71B9a",
    "0xa31D0884C1C8E21092af0510CB70cfCca6D009de",
    "0xec613Ab9A2Df9ca12AE0D690E95EFCB075505fF9",
    "0x4fE82D8272611342807DA3eA0d853180333115EA",
    "0x405dFe0f6cdBb437F1E62AeF9677832c6A831bcA",
    "0x2db76087AB6719Ca091dc53B4971a746b57EaEE2",
    "0x9Bd226EfF925470f5671181DaF17fD4661580805",
    "0x1D981567D01645697D28D0c1c6eb7e06ed4A2E39",
    "0x8A0d92dD1e3F415a4034f20FB4774c8C05873066",
    "0xAE97b142FD5f777aa1CCFd77d25C9Be1902C465F",
    "0x75335B4c35c701EEdB8BE020F02d683c845368Be",
    "0x8376a3468DF07DeBc9e468889BBB7b6d774a2761",
    "0x7DaEb8C98381ecf613Fd6711696DA0Ef138ea38F",
    "0x17f320E6079E97B2D20Cc8E8fD946aB6A9f87683",
    "0x2d569972Bf7F33319955f129ADdD63755D39AD27",
    "0xA9cbA77d52B0555fA996A528D68b4CEFBB541599",
    "0x7Dd03A6634D3Ca0F1B7D7B46f0bE62A142e25E9F",
    "0xD259632aB3E17a1c4A05Aa5EB5527dC2Ac0F1004",
    "0x9BBa1C0CdC31E22b7C0b99878544B11736fc043c",
    "0x85f5De9d8C4B0450F4979DD9406C73E29680fe21",
    "0x5bcCd013502f34305f8d6396B3E3A525401F59BB",
    "0x88e70650BfE7Bf2996AAE4826E305E2B1eD775F7",
    "0xF25A00bB9844053727765b00B3d2Cba35dbC7a73",
    "0x1729C4e316aF92965F9A100537B47903e7f7A82c",
    "0x159fe66a440DBa073D5a48CbDD1592BA3d0eDD03",
    "0x7d4F74820132a10CEc69EBFe5b43Be4A92d6d99D",
    "0x760dDd501dDE7Fe6AC58E1b39D45be7743E20637",
    "0xb09f09B1c3dFADFBfE1dAc72cbcaB6451d00F433",
    "0xEfC334F754D6B9a193EE9A423aad561efa1408dE",
    "0x72e131366B15E0C40bBb89283c42EfeBABf9D7dE",
    "0xcc20ac23ada85f2e060a7c530d5ed154ff73d32f",
    "0x40E505d11ef380Ed287F6Ee87FC83A1250fD1854",
    "0xa262f14976B005D8Cd29d4283F218b7eeAE31aA7",
    "0xaB52814573F8fAeEd944aaD9608cdc7cba164bF6"
];

let whitelistAddressesWL = [
    "0x41547E0d4F0f42286B9d460a50506785E7c89BAD",
    "0xF28a30940c4941c04aE699B306CD3D7878bdcCB6",
    "0xB813fdC1676F9377F1B7964B92D3c097e96445F1",
    "0x7eCe642278641f59728b7D3b3eB3A5b2e8619D7E",
    "0xF96fd6F445dc6B20af87BD805c362E3b503eBC49",
    "0xBd02E5f2C293E0cae578A530DB5ffbaB453AA467",
    "0x4487595d1959db7A86DbD273B2506E172d3742C6",
    "0x4012dBd303447EC2EF495f18c1f95CCf01a33594",
    "0x57cc7a82776ac275ace75c900e6a7126cbb8c29f",
    "0xBa64e8FeCEeA1d3b4935756E7FB4eAe87D30D643",
    "0x7e23f543B4b56F79ce2ae9F1DB0e88528B241f58",
    "0xe1cDF32847258e5872dcFbB27958A64DA3327117",
    "0x2f20806ab5B107475f843E6a60C07761892B2090",
    "0xED72476C07d31e1F4383b789606D367cbD1Eb7F5",
    "0x7F88df6ac16e5ee4A75B98F4842cA2c1E7EA88cD",
    "0xe60Ee66bD4db2e6Da0F0c76275cB318AdD31fBF1",
    "0x0f8Df167A8751ea7Ff211a1309DAd6aFe2d00376",
    "0xa6111D2d211513F58Afd6667f01283B3e1D97630",
    "0xe7967299A106A562D05aE6A02b87A690D0155261",
    "0xE7e5F42603Db30e253441061B1941A92DE49Cf82",
    "0x7CfB6471D2A9913b7d27D3f3983751eFA028Eea9",
    "0xFe6e1cf89C3E1697707Facf9D022dDc00F75ED01",
    "0xaC786Ba44DD3A8AB9EcBCC7DAcA9f4c89C739670",
    "0xA70C0f6BfF4fD0002459b75c5349A5FA18F37303",
    "0x14Ba3E6b5a3aBE6a778297434dBE45f0c47e12C7",
    "0x37FFe79d00C8c33E3f9622ac940e46BFa56d70a7",
    "0xd24C655018c991828c2779e6D31aa3404727A9ae",
    "0x0FA14daa080D7f55DFf8F3e044B4b7A4864f359b",
    "0x1A2e416c597bb64c2AD1cE23E6ADEE6e17656c80",
    "0xF0eC57EedDA3cC35A13AE71918844259dFf777e7",
    "0x5dC42520f3dBdCCb59547A25Ee1BB6E621d83462",
    "0x7Ea9Ab9C91B0a15c4C2A479fe198adc7B0A62f7B",
    "0x68e4b1A3d7315F27a78550e5CDa86fB27cbe007A",
    "0xab1417494c348a8defbe7a0d5e7b4547da286c92",
    "0x2D3fBd62A90Dd58C5606975b963498739ebC2CEd",
    "0x995F625E36B17170Bd946F9610d07A6666A7ee7c",
    "0xae4b25d333ffadd4959658d00c8fe857d5be396c",
    "0x5e10cb1b0e8f673f98a5a5a4001a375fa1cb6748",
    "0x5F144158970B53f6dCBB761e7B02B6455d05C861",
    "0xfEF805a586580a845440ea303324bfDd2FC8AcC0",
    "0x545582D94B338bC445e906a3c9877Ed2DFf19E90",
    "0x6F5f2271e70A98409B8c091291803Bb894E8aa7d",
    "0xc2e78371789CC67DF6f18774ca4A35882044C1E5",
    "0x72CcD287CAf23D839ab9760EE3C55DC4Db55321A",
    "0xcd21d3b0e8fb11287927c7cffd3e329fcc7e17aa",
    "0x1AC0FA9Ab44b19d495065AAc4931e3E92C3305D9",
    "0x6eB1a4236fBf25a1E7fFF8985D5FD813C5eaCB1a",
    "0x3AB66471fBA1804AA8b95abf0b0f8Eb2aD0FD7d8",
    "0xBfdC89253c41DA7b3705ad478F78D2c810A9657B",
    "0xc8e27bd06e6B9B31e716fA23681AfBadD75C1f5f",
    "0x2Fda6b369677BC0637B27b276125683359d1C995",
    "0x5B22579045aa510d979766A47E58C78453EF13EC",
    "0xB7b2a7cB1f3E0E23aca9A6ca39372A53f2560211",
    "0x49548D3Ba62403F26bEC711886704E1205a45fd8",
    "0xE77262fB26c5FF7De2C56f4e016C4CdB06e1779e",
    "0xBDB969A121D3Bd526D90996D426e815C1e88652B",
    "0x97D4d56c8f1eEc783CC5B303CbE7D5b501E6Eb79",
    "0x117972f869f12fd5Bd31622eEB12e166ACAfB6c8",
    "0x426A0B4DF2ddff42A52f51032Cd48dA62B489ebb",
    "0x2230F9D381721A73a88809C3375299FE996C3B34",
    "0x4774730Fd1A81029d8AE9ebF9bcCb6db31b46a5B",
    "0xBAdFd1949a51b18B35d28b8bd8BfC333205b4Dd0",
    "0x4D0cc05daD3Eb7D10a4961d4AedDFD60c3076C6e",
    "0xB33D68146533A4A21d98e8E9b4d69803E1CE05C4",
    "0x8853F1e3Bfa863dc03De52155db2F23AD384a6D0",
    "0xf83733F9fEBc2b06a8CEc34d9EA26bBd7cA05569",
    "0x5afb33754273eEd333b071C4055757A490c7dBa4",
    "0x817682851004Dbe5b860D641633Be7E8b4949c64",
    "0xB1e2526B4a88f6bCF731Fa6A288558c13af797f2",
    "0x01b52c5b5c893d822a0b8e515a7709ca5d900b5f",
    "0x73156fbd3e92ecb18cf202c20e05f8c23e8bbc4d",
    "0xdBd5A41581F06FA33cE5E9b8Fe0D546D2A3bA6D1",
    "0xD958D165957A20A2D3019F6078c48f4FC628c6D0",
    "0xd6c9961b2658198035dd4ed1d85dC10C58629127",
    "0xef9457C120eA04Ec66801F5554a71200326C1631",
    "0x9808231271124715119C535BD31838de71661F03",
    "0xFd2a795666Afb2266Eb2136a74D1a95E0535F259",
    "0xDa4bBC4014076E0B37B5E8b946feF74F333128AF",
    "0xA9171FC7B7Df76Ec09e0D94d46866c1A7831Bf10",
    "0x381B730eB1Aea34AB53F56b4b0bc7f9C251D965B",
    "0xDC585f2774a154fef87EAa666d1ED2bBc187947F",
    "0x091d8F6e2570bB77d2623E390D28278BE4BbD218",
    "0x4f765b9FCD43712404dC32CeE0bd4cAdf073CC05",
    "0x514af73E062dFE28901Abf26C478e0f3E3ad03dA",
    "0x4c8E22d1c15Ed6AD0fE36e6d5D7fc80e0Fc00b38",
    "0x798c4D95F5C186ea4fA2838CDa5e797A28f982E6",
    "0x7eA34bAb4F317596DC068fEF7c6030197cb650E8",
    "0xb8F7196463a52741d16EDf7B66738C1EA0eE7966",
    "0x4f2f19F64A5891115BCDad2446FEEBF2E82eb6C8",
    "0x38031F1493064646bE7d4670aA90588255c3a3C8",
    "0xc9579CAAcf1c83756450cF5F20D4040F433db562",
    "0xDC706A6c1466bEf76E727b3e30349e51d23Bf79b",
    "0x3b0D5d308e160c0aE05E9e6e1cAe8a36C4462eD2",
    "0xB93a7932C807e8adF115C58b4D7825b0572bFe1E",
    "0xd5c86C2e4Dd07FCE5354AFF248A408B686d6fD60",
    "0xb1ADD88461eC550e0C6DFb3CAc7654512F69BbE8",
    "0xB89bFcD5C4F5bcD081989bab7963D7eC01400CaF",
    "0x7b39291665de458894Fc1ca0D316d642FE567f7e",
    "0x434Eb950eF67470F1c255BDAcd1b6A39e1c67A14",
    "0x9308E6be6373B9d0593B23935f0019c1c46B6168",
    "0xBb1f78fe4729eFe133825f87DBbB247CDB3AC0C0",
    "0x77B2F9a5DF2efc260B6f1b57C636f64C3b3e926F",
    "0xc0717cb3AFC07e38C1ae67acc5f397dA8FB7eFaE",
    "0x666635C982276c2773f4F53978b56EEe42491498",
    "0x25389037d62DA127C7FeaeaB5987e0f5cA5fA60A",
    "0x414480e92394FBbab283e1174aBe5F7b16355514",
    "0x2e00846592959FE73C57d57860CC9D175744711e",
    "0xA3069CFA9C0aA758965E29285b24090e816e2308",
    "0x72663d91773b7ee255822322a99aa2860ff3a85a",
    "0xad431E740Bb07848a85415df29541ECFe2F62C84",
    "0x183DfBF69C1e6D76931288F5614Cb2214b12bB6b",
    "0x5Ecd6259beD9Bef30925fEd4eE68B2190EaF5eB4",
    "0x5bc6F0662134D59d5eD145672C3137e1f3bCFd36",
    "0x68696D2d051005c195eb07EFF091457F6B2D21ED",
    "0xa8467Ce68F4c2aF9C0B21E5f9A140eb691c90768",
    "0xf679eD02bC523f78473e8660A617f3c16127dbe2",
    "0x6D9916fc54A27B8F936cCb3480CC6FBbbE7cb23d",
    "0xCeA6d4bAaf2155859c155D3A3CC92842c1508a96",
    "0x248610bcf5b309886890d253BCd32B3cc25aF786",
    "0x0431de6d4c9ed5712a9df217c7d2f5f7e670d6fa",
    "0xE33BDe7E83D648D30B7399A3F8843D9EC11d151F",
    "0x378a74911747e140aFFB121318D63E70bdf30C35",
    "0x3537477a12A098be61Ecb7eCE34719B0C162139B",
    "0x8f32aE0b4180C5320aAee0aBe8005a07BE941F46",
    "0x50611CC468a1a02f931c807417F57EE55898E496",
    "0xC99e255f88E39889A5FeC0B8D58A2Dba426BEcd8",
    "0x4132C0C09D51e9d85599291648f6a81357F7D821",
    "0x3fc36aDE615BF6e4581A6CF91B80fD46165cbdBA",
    "0xD41CEEDfBE5ccd671199f250F4eCE52AFFfC3aFc",
    "0x2cD795a235Fb1C0cdc80dD8975bA69688858a285",
    "0xc6473856a74732374ec3aF4f2B04cb94A28f60B1",
    "0xD89d37B9b1058f5B5D49ebFd067b269087Aee7e0",
    "0xb4b2Ce2d75B9D7379f93f8dD4D42Ba015C8F7ea4",
    "0x69996A1C2cF3e94c5b2856A4Df1089D3eAfB1660",
    "0x1e7CE36DA0bA4821a36582c407A654D214DbCFC3",
    "0x435F3e8B4ce25aEA096F423fc61BC334B197e019",
    "0x6B0e9E027abc8BAaaC1761cE515C232A111129f6",
    "0xB00D57CB6c332d62CcB670B2E61DBAb917A92D51",
    "0x0f2D050d7c0926a50B3c4C6c72A5412812C79BD4",
    "0x8Cf61E409b9B90C1Dfa12D35810F7711371AB68A",
    "0x35A2ab7Aa5089c860B51c9ED148beB79F2835b93",
    "0xc756B2681E1fD93ec3dbF114E36975F82BD103eb",
    "0x30aA851F3905deD4eB7a3680FC2809Aee7f47D07",
    "0x2395E61Be7Aa1A2823F7c85bF8D6cdE348760F84",
    "0x87F3770D0E364419Ca0307b2fE53bbF3E6089B09",
    "0x8E0EDD0fd4CD8A8ABbd77487469769Cf4888B181",
    "0x26712645ebF56AfaD0Fb877e3D2109E02106F074",
    "0x7D3dc2547f507Df0cfe704e6D17E6A8541e6f36d",
    "0x3869E8e565d2FF80817439c680DEbb937947cAc6",
    "0x312271094ac441BF566184a37a7fA5ac9e397902",
    "0x5670C9aE19Cdb966dFE91C280f1D3FdC6999923e",
    "0xc4f5be6b7951b154aa73115f700604eeb2499ff3",
    "0xFbECf6BEF97f1DdfD74195D52dbBe25CC7c086b0",
    "0xb7f06401A2954d2AFb3e1380Ee0dD8Fe3726471D",
    "0xc2484fBFb220D093AA7DA5BEc09caD2E403AcdF6",
    "0x02F75Cc5E776A241E7b5738f24b61D222B62E404",
    "0xC60517F48c10C98160A5a2383aAb9C6606cEb624",
    "0xab25D9a34B87D30098e090b8AC608acB8AB98341",
    "0x69e9541Bae37fd07cFfcf4Bb9f1816B0121cdeeC",
    "0x5B38dC8dfa9a171aB80b6AD06E6E57f9177a0432",
    "0x0828d7eCD3B5D6461Af19A9B21bB9F9FE2Dea43E",
    "0x1523D8E9f6C6d6Af3Ebc3b75E5503f47d57C0504",
    "0xE4EA824CD80E61E101Af246E9B217F155Ee2E090",
    "0xf727F8db63569104C8D9FBf38B761cFA80aeb516",
    "0x60DB1B023cc89AE61BCe47c470aFF4fFBF674e35",
    "0x7c947ccE5f01F2b5De341266b30D40811b094944",
    "0xeD9393f4CbE273D94002975aB9D73420c92F710a",
    "0x1ee5c1DCf49433BE8696c085c5edF7a1aD467E7a",
    "0x243AFdeC8f104119Eb409ca776C9C2f4941f5307",
    "0xf055E89033b00Be0Eb46f8302fb106449CFe5AD2",
    "0xfE3Cf487565A3Cd275cb2cbf96a395F023637D86",
    "0x31ec5BE3Dba53012280B85243BBaB558c41b163c",
    "0xFeC236Fb18250fD9880f280601ba15A9F47bE7F5",
    "0x52eB606f94091A2BA4f985D98C62880a9BA591B6",
    "0xdf1AfB318bEF8Cf3047dF6d65177145B01E58c92",
    "0x2d80B1d4009C47FB257216725Ff9478C65BDd259",
    "0x4f4E61b556300fbe349adF08a722eeE081643f48",
    "0x24313F0495e0Db6212ef9CA143ca9a94e39CC700",
    "0xC7d8a6955859d9903c63216C8345A3DAdC8d146f",
    "0x21237Fd9034b76fDDC9d213f8AB38010ceE4Ac75",
    "0x5638de887060aaD096b9c8C6E93f16292e429471",
    "0x6c3850F04b48817557aFAB3c79015c3E86D6D0E5",
    "0xB67A054572a8fD44C86b4e563d4ff820c257B9CA",
    "0x1Bc9E3c3C410C41a2D784107a4fC89FE1378F441",
    "0x472041c922dF8663e071932C6bB7dd3978C63109",
    "0x4F0F9F2DF0B2149a364D6958B4F6597F85C66C32",
    "0x2518d14b6797D5e974e9bF6B1345B3f7970a3954",
    "0x17b319479497019A0AeB39c3E1E7ECfc5DC8B94A",
    "0x23438ff96eaa034fEDD1EFFE4dC0616ddC3525c2",
    "0xF3c7Fc54F15fb4a8DC9D6E111dCD3B0c24d416d4",
    "0xf5b8231BF3792b7080e705a0B162b65cB9902C87",
    "0x6d9f24aFC7e0C2a102124B769bd9E8DfE25f303c",
    "0x600A889107f8a7121aa71D5CF93BC24499a64b48",
    "0x42b4F5e916eC0fD4b01F4D01536cAc180091fE1E",
    "0x8FBe84F3Fefc0A9411bF87A216875110468E4910",
    "0xB050FdE04c1E5aaed62f94F7aC14d5Ed9b166057",
    "0x70e58Ef239dA7DA1D5332C34FAD21e636EF483Cf",
    "0xDA97b3b43DB3501a220b713A9f4F2D359C722915",
    "0x9Edc8F80B08B223617E1385561f5c8be4Aa1a73b",
    "0xf8648D32b882E97C44EFB05824cf3EaD87DF729f",
    "0x67B248b88077b49a8aF84c40fB89A22E8E06074f",
    "0x77bdd60a3537e1e1367D9114a1b7017753173A22",
    "0x82fBC709258990cCE657ba61236e26cDDB2D7A47",
    "0x5F1F54D347e5Cb097bf83d053EbBf5E34F1CA65b",
    "0xa84dd0c6a8f5DEA65672f1B82DAFb79b3d0Db748",
    "0xCF5c36072602b8519427CFf5bcDDa69EbDe71B9a",
    "0xa31D0884C1C8E21092af0510CB70cfCca6D009de",
    "0xec613Ab9A2Df9ca12AE0D690E95EFCB075505fF9",
    "0x4fE82D8272611342807DA3eA0d853180333115EA",
    "0x405dFe0f6cdBb437F1E62AeF9677832c6A831bcA",
    "0x2db76087AB6719Ca091dc53B4971a746b57EaEE2",
    "0x9Bd226EfF925470f5671181DaF17fD4661580805",
    "0x1D981567D01645697D28D0c1c6eb7e06ed4A2E39",
    "0x8A0d92dD1e3F415a4034f20FB4774c8C05873066",
    "0xAE97b142FD5f777aa1CCFd77d25C9Be1902C465F",
    "0x75335B4c35c701EEdB8BE020F02d683c845368Be",
    "0x8376a3468DF07DeBc9e468889BBB7b6d774a2761",
    "0x7DaEb8C98381ecf613Fd6711696DA0Ef138ea38F",
    "0x17f320E6079E97B2D20Cc8E8fD946aB6A9f87683",
    "0x2d569972Bf7F33319955f129ADdD63755D39AD27",
    "0xA9cbA77d52B0555fA996A528D68b4CEFBB541599",
    "0x7Dd03A6634D3Ca0F1B7D7B46f0bE62A142e25E9F",
    "0xD259632aB3E17a1c4A05Aa5EB5527dC2Ac0F1004",
    "0x9BBa1C0CdC31E22b7C0b99878544B11736fc043c",
    "0x85f5De9d8C4B0450F4979DD9406C73E29680fe21",
    "0x5bcCd013502f34305f8d6396B3E3A525401F59BB",
    "0x88e70650BfE7Bf2996AAE4826E305E2B1eD775F7",
    "0xF25A00bB9844053727765b00B3d2Cba35dbC7a73",
    "0x1729C4e316aF92965F9A100537B47903e7f7A82c",
    "0x159fe66a440DBa073D5a48CbDD1592BA3d0eDD03",
    "0x7d4F74820132a10CEc69EBFe5b43Be4A92d6d99D",
    "0x760dDd501dDE7Fe6AC58E1b39D45be7743E20637",
    "0xb09f09B1c3dFADFBfE1dAc72cbcaB6451d00F433",
    "0xEfC334F754D6B9a193EE9A423aad561efa1408dE",
    "0x72e131366B15E0C40bBb89283c42EfeBABf9D7dE",
    "0xcc20ac23ada85f2e060a7c530d5ed154ff73d32f",
    "0x40E505d11ef380Ed287F6Ee87FC83A1250fD1854",
    "0xa262f14976B005D8Cd29d4283F218b7eeAE31aA7",
    "0xaB52814573F8fAeEd944aaD9608cdc7cba164bF6",
    "0xa761CdAcc37d65E2b09A4F7965711f261228eeB8",
    "0x31171800B2C2bB8348FaB708DA7fC3D5fC8fde07",
    "0x89e70e1ce76B1f14581277DFa6fA4015B9F11657",
    "0xF3e883AFD2e58D518D04DC42219b16a98f474290",
    "0x5F4f0E17067c9a9F9DC2339eccc09541A0256a7a",
    "0x3060dc7b800fec0c8fc6f7966d9f3fa5f30c43d3",
    "0x2007B90C18b28049b2f064A7E184d8f48c02FeeA",
    "0x731f5a91F0249e37D944aFfa0Dc22EA05485f1BA",
    "0x325FC6333cCb80BBEca15A5665C33868ec40E335",
    "0xFe23887a4CF160f06D7E217B10C7C12560f2026c",
    "0x88629E277ba6e22809E78402e9Db5e8285cf3A14",
    "0x86A77162072177999209e1a05161A9C9fa4dd211",
    "0xfA46E7E42640c64708451b3Ddee6F527daB40df8",
    "0xaCDaDfcdaD09b3B6A30c66cbF8292A64DD011C13",
    "0xc79D1e613d62A89a4A025DF829b3318f34D0540d",
    "0xd58F769CC9CF584045AdEB0c6C4A25f2Aa05fA64",
    "0xCE61b88aE9a9Fe70E0cf465D234addb162A38b94",
    "0x0b0971bb2Ba317A15f52D6556fe8A72478BA2B60",
    "0x3eC269Fa04127D2a9f6C37D2461eb3442373483c",
    "0xF51cA8fD29A9F1025c84746b0321C3987C7a509D",
    "0xc90BC2aF54c8fA6489d9FaB9ba27A263a676C007",
    "0x3f6CfCdB7FbF6F503803Ac5d19c10D5Fee063181",
    "0xDC3d3884A1274C45b13108d4D8c7A7d0fD3f6076",
    "0x9b4F3Ae59043E37B1953C2630c7D0b660191481D",
    "0x545f4677a2227360cf70d20c1813df0345dc6f78",
    "0x75d8c3fe9A61Cc38266D883e3060d648eB5f071F",
    "0x2a38120DfFdB1b62A6b1f3e56d288980f57CdF30",
    "0x03C12E6683bc893464E23364998207B6F12ffbE2",
    "0xa430dE458a8C142aeCC7559173421454b812770D",
    "0x850b87D03A705CfCBD46b1Be9a540ac4f553a032",
    "0x40Bf75304596cA5ce424F6CC70Cef7CaE5aD8AF2",
    "0xb61ddb0370ae548486101b008D8901D705c3EdC6",
    "0x540bc21784a3bcd72aB77C830381f3EEE4548A5C",
    "0xdf3a49f85ffe85c72e35e43bc4f4b220f394249a",
    "0x5Ac79dBDf2E9587A3325d79D9045a48d9e3eab0d",
    "0x8D479e19cb2F896F1879Bd56f924eb2a6Ef29e18",
    "0x329c1e65227c5296f6e991f957a5f282ce2bd9e8",
    "0xa1434Cb31D3B3e48062efD3CEe11385967bCF282",
    "0x77904f1a4E9D81CAFb47db7Ae5DBA46456683d10",
    "0xa2fb09cB58Fb0F0916C35BFBe0B3aC55E551fe4E",
    "0x40BAd26432912b416CD5F665b99812c5E9729834",
    "0xF609d9E0d10C4a9Bf513de3037671d041EBC6bC3",
    "0xd80b5cd3ffdd88b82ba47fbde6d8ebd4f75b44db",
    "0x37e6010357A84267D11F4d82A534597AFCA77e05",
    "0x87e85f065d7540dF24D9487E8f86c4dAe652B19b",
    "0x38B06dBBC841e872425eEFBc222de3fA08d00D27",
    "0x787a8b6f6a9855802adf0d12b82956a9814c8e28",
    "0xBa0b2426a9B1435D5304BeF7F2D0C761428db624",
    "0xB939a33F9af217B1A8e3226c9D59aF0c87f27D2c",
    "0x71A6A1FbDaafB5b389cCAEaa1D84d0Ce6B7520De",
    "0xc3f94a20414C34a9c3B7C16aD4d1126c9f3f25F5",
    "0x468b98B17C278909975A1A211ee039D4A6614520",
    "0x41C0F1468F2731671A6Dbe5135383A28Ad47a5B7",
    "0x87E205e53D796dDd902b54e9AD4EB3e492dc36c8",
    "0x3810e63ABDF61cc83f676000118A877eC1BA58d0",
    "0x3fD7fA51449d821B1003a1b42f16D24799A23E6D",
    "0xc2Cd00c98dA487152ab8069Dd08F7CaBEAB304ac",
    "0x7e78294f993e33b21C40f6df20Efd64c2D5d5dA2",
    "0x85B71dD2d6a6621ff9a900F93EaF268A110d0e0e",
    "0x0a44B10813C7bdb675eaecC44cD34dB3D1C0b243",
    "0x05A7328F81FB1eD77a405caC4621d2EB33530F00",
    "0xB75595e54b0B3E031B8cBfB859AA3Fb879DAC4bd",
    "0xbA719e5490915781A10711Cb4d0020691c51F500",
    "0x2cAfE7729150EE4366B4b85271E777426a74D3e5",
    "0x403492A4758CF1CBbd345F5fA80D9e5C6e2f0BD9",
    "0xF707D5af3128e1df27545e9B526e3F5C97C342d8",
    "0x28b10f33238dd7ebbB630be5B7b9C961c5a294A8",
    "0x44103343eC050cce457dFC8dCa9B3e39601819DD",
    "0xeb351a3B5b5d7c46E6a2Ea796f94c8068aF84D6c"
];

let whitelistAddressesWHALE = [
    "0x21237Fd9034b76fDDC9d213f8AB38010ceE4Ac75",
    "0xD6ec01A88f52A11681AAFC7eeEfe6602cc351657",
    "0x5c096D684dF697B7aD963caa2592036128A0faF0",
    "0xF6397a57A43a67fD855B9465B8Bb73a32CaAE8EA",
    "0xF09793777F25bFaAA1F3fF3d610673d531073a9d",
    "0x8a8f3dD2E7c4e17F43Fd8C989e6288049C26588c",
    "0xE55E2E7F6a58403603d9F49F2da511BadF94BA07",
    "0xD6Ba38B9729ec6EF2D1cef640eA6229A87E3753E",
    "0x71eBBC656D937Bfd4FEf55687dd292933D125B92",
    "0xa49D85a26fcB5b8cA0c78692439591Bc718e2D21",
    "0x8D5E4b486E727Bde6C2ebd5013341aBF82EaF9E9",
    "0x595b0693C9A54C8351b11dAF77334e4d1A7421E8",
    "0x6d3a14E4Ccb26DF08F7046b1A5DA0eae309a2128",
    "0x7004c7F20F50A55A6e6fb002a4AE331A7026f93B",
    "0xb1EAA160b26996BA2D66638f58705801eBf25e21",
    "0x2cf7c8a42E2BDc30A50453979A3452279C05532D",
    "0x71E2Ba6fC204278bd1D9D0b62516bACCbACF806B",
    "0xbA4Ac042BFEc01E44dBDD947BA8E2f4Ef475d1e0",
    "0xeEC80480D91B31a30B09a347A071d7AFd640953c",
    "0x79741f55A40160DA2930a45eeB498B4F23a46f40",
    "0x968B8F746391c8c17dD4656424FA65eC9CD09aaf",
    "0xEaB73D4E656bBfAB1bc04BD944390b3846D4f685",
    "0x040C204A0ecCdf9E5471dA263a9B75c0110807fB",
    "0x5d0eCE4CAe6295becdae25233F8acA23a5c512f7",
    "0xf1F10c6dA12F1bd456AAE4a10ebe6CEBB6c9d2D2",
    "0x4adAbFD2383021ccF3D0DF73a1127e47130d56B9",
    "0x451B342f6b25c41D9fDD7a0D135d03CfEd19e3eb",
    "0xC91D55707848c3DBc20d7d1b357aDE3499ba0a16"
];

// mode: 0 OG - 1 WL - 2 WHALE
export async function checkAccountInWhitelist(account, mode) {
    if (mode != 0 && mode != 1 && mode != 2) return null;
    const whitelistAddresses = mode == 0
        ? whitelistAddressesOG
        : mode == 1
            ? whitelistAddressesWL
            : whitelistAddressesWHALE;
    const leafNodes = whitelistAddresses.map(addr => keccak256(addr));
    const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });
    const root = merkleTree.getRoot()
    const sender = keccak256(account)
    const proof = merkleTree.getHexProof(sender)
    const isPresent = merkleTree.verify(proof, sender, root);
    //hexProof = hexProof.replace(/'/g, '"');
    return isPresent ? proof : false;
}
