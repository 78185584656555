import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData, fetchGeneralData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import { SpinnerCircular } from 'spinners-react';
import VanillaTilt from 'vanilla-tilt';
import swal from 'sweetalert';
import styled from "styled-components";
var Web3 = require('web3');
import './custom.css'

const PROD_BASE_URL = 'https://mint.collagebots.com/'
const BASE_URL = PROD_BASE_URL

import { FaDiscord, FaTwitter, FaHome } from 'react-icons/fa';

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const OLD_ContainerMintSection = styled.div`
  width: 100%;
  padding: 20px 0px 20px 0px;
  border: 1px solid #CC;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
`;

export const ContainerMintSection = styled.div`
  width: 100%;
  padding: 20px 0px 20px 0px;
  border-top: 1px solid var(--secondary);
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 300px;
  @media (min-width: 767px) {
    width: 500px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 2px solid var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  max-width: 200px;
  width: 90%;
  transition: width 0.5s;
`;

export const StyledImgIcons = styled.img`

`;

export const RolesImg = styled.img`
  width: 100%;
  height: 100%;
  max-width: 350px;
  max-height: 260px;
`;

export const RolesImgSmall = styled.img`
  width: 100%;
  height: 100%;
  max-width: 210px;
  max-height: 140px;
`;

export const RolesContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 100;
  align-items: flex-start;
  @media(max-width: 340px) {
    flex-direction: column;
  }
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [mintAmountFixed, setMintAmountFiexd] = useState(false);
  const [debugText, setDebugText] = useState("");
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false
  });
  const [mpTotalSupply, setMpTotalSupply] = useState(0)
  const [randomTimer, setRandomTimer] = useState(0)

  const addTiltImage = () => {
    VanillaTilt.init(document.querySelectorAll('#rolesContainer'))
  };

  const claimNFTs = async () => {
    let markleProof = data.higherProof;
    let cost = data.cost;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = (mintAmount > data.freeMintable ? ((mintAmount - data.freeMintable) * cost) : 0).toString();
    let totalGasLimit = String(gasLimit + ((gasLimit / 100 * 6) * mintAmount));
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount, markleProof)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then(async (receipt) => {

        swal("Mint succesfull!", "Welcome to the fam!", "success", {
          buttons: {
            opensea: "Check on Opensea!",
            ok: true
          }
        }).then((value) => {
          switch (value) {
            case "opensea":
              window.open(CONFIG.MARKETPLACE_LINK, '_blank').focus();
              break;
            default: break;
          }
        });

        //console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(await fetchData(blockchain.account));
      });
  };

  const setupFixedAmount = () => {
    if (!mintAmountFixed) {
      let calcPattern = data.isWHALE ? 20 : (data.isOG || data.isWL) ? 15 : 8
      calcPattern = (data.isWL || data.isOG) && data.nftMinted > 0 ? 10 : calcPattern
      calcPattern = data.freeMintable > 0 ? calcPattern : 1
      //console.log(data.freeMintable)
      setMintAmount(calcPattern)
      setMintAmountFiexd(true)
    }
  }

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (blockchain.account.toLowerCase() != CONFIG.OWNER.toLowerCase() && blockchain.account != CONFIG.VAULT && newMintAmount > CONFIG.MAX_MINT_AMOUNT) {
      newMintAmount = CONFIG.MAX_MINT_AMOUNT;
    }
    setMintAmount(newMintAmount);
  };

  const getData = async () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(await fetchData(blockchain.account))
    }
  };

  const getGeneralData = () => {
    dispatch(fetchGeneralData())
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  const debug = async () => {
    swal(debugText, {
      buttons: {
        ok: true
      }
    })
  }

  useEffect(() => {
    getConfig();
    getGeneralData();

    if (window.ethereum) {
      ethereum.on("accountsChanged", (accounts) => window.location.reload());
      ethereum.on("chainChanged", () => window.location.reload());
    }

  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  /* auto move mint count
  useEffect(() => {
    const timer = setTimeout(() => {
      setRandomTimer(Math.floor(Math.random() * (3200 - 1000 + 1) + 1000))
      if (data.totalSupply > 250) {
        if (mpTotalSupply < data.totalSupply - 45) {
          setMpTotalSupply(data.totalSupply - 45)
        } else {
          if (data.totalSupply > mpTotalSupply) {
            setMpTotalSupply(mpTotalSupply + 1)
          }
        }
      } else {
        setMpTotalSupply(data.totalSupply)
      }
    }, randomTimer);

    return () => clearTimeout(timer);
  });
  */

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ paddingTop: 24, backgroundColor: "var(--primary)" }}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.png" : null}
      >
        <StyledLogo alt={"logo"} src={"/config/images/collagebot_logo.png"} />
        <s.SpacerSmall />

        <s.Container jc={"center"} ai={"center"} style={{ textDecoration: "none", textAlign: "center", display: "inline", width: "70%" }}>
          <a className="socialIconLink" href="https://collagebots.com/" style={{ fontSize: "30px" }}>
            <FaHome />
          </a>
          <a className="socialIconLink" href="https://twitter.com/CollageBotsNFT" style={{ fontSize: "30px", marginLeft: "8px" }}>
            <FaTwitter />
          </a>
          <a className="socialIconLink" href="https://discord.gg/UFEkYyHJGY" style={{ fontSize: "30px", marginLeft: "8px" }}>
            <FaDiscord />
          </a>
          <a className="socialIconLink" href={CONFIG.MARKETPLACE_LINK} style={{ fontSize: "30px", marginLeft: "8px" }}>
            <StyledImgIcons
              onMouseOver={(x) => x.target.src = BASE_URL + "config/images/opensea-focus.png"}
              onMouseOut={(x) => x.target.src = BASE_URL + "config/images/opensea.png"}
              alt={""}
              src={"/config/images/opensea.png"}
              style={{ width: "30px", height: "30px" }}
            />
          </a>
          <a className="socialIconLink" href={CONFIG.SCAN_LINK} style={{ fontSize: "30px", marginLeft: "8px" }}>
            <StyledImgIcons
              onMouseOver={(x) => x.target.src = BASE_URL + "config/images/etherscan-focus.png"}
              onMouseOut={(x) => x.target.src = BASE_URL + "config/images/etherscan.png"}
              alt={""}
              src={"/config/images/etherscan.png"}
              style={{ width: "30px", height: "30px" }}
            />
          </a>
        </s.Container>

        <ResponsiveWrapper flex={1} style={{ padding: 24 }} test>
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg alt={"example"} src={"/config/images/example.gif"} />
          </s.Container>
          <s.SpacerMedium />

          <s.Container
            flex={3}
            jc={"center"}
            ai={"center"}
            style={{
              //backgroundColor: "var(--accent)",
              backgroundColor: "#310e68",
              backgroundImage: "linear-gradient(316deg, #320a68 0%, #5a0f40 74%)",
              padding: 25,
              borderRadius: 15,
              border: "1px solid var(--secondary)",
              //boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
              boxShadow: "rgba(0, 0, 0, 0.65) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
              height: "120%",
              margin: "auto",
            }}
          >

            <RolesContainer
              id="rolesContainer"
              style={{ transformStyle: "preserve-3d" }}>
              <RolesImg
                alt={"mintRole"}
                src={"/config/images/freemint_banner.png"}
                style={{ transform: "scaleX(1)", marginRight: "auto", marginLeft: "auto" }}
              />
              {addTiltImage()}
            </RolesContainer>

            <s.SpacerMedium />

            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 50,
                fontWeight: "bold",
                color: "var(--accent-text)",
              }}
            >
              {/*mpTotalSupply*/data.totalSupply} / {data.maxSupply}
            </s.TextTitle>
            <s.TextDescription
              style={{
                textAlign: "center",
                color: "var(--primary-text)",
              }}
            >
              <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
                {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
              </StyledLink>
            </s.TextDescription>
            <s.SpacerSmall />
            {Number(data.totalSupply) >= Number(data.maxSupply) ? (
              Number(data.totalSupply) == 0 ?
                <>
                  <s.TextTitle
                    style={{ textAlign: "center", color: "var(--accent-text)" }}
                  >
                    Loading...
                  </s.TextTitle>
                  <s.SpacerSmall />
                  <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                    {CONFIG.MARKETPLACE}
                  </StyledLink>
                </>
                :
                <>
                  <s.TextTitle
                    style={{ textAlign: "center", color: "var(--accent-text)" }}
                  >
                    The sale has ended.
                  </s.TextTitle>
                  <s.TextDescription
                    style={{ textAlign: "center", color: "var(--accent-text)" }}
                  >
                    You can still find {CONFIG.NFT_NAME} on
                  </s.TextDescription>
                  <s.SpacerSmall />
                  <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                    {CONFIG.MARKETPLACE}
                  </StyledLink>
                </>
            ) : (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  <p style={{ fontWeight: "700" }}>
                    CONTRACT OPTIMIZED - LOW GAS FEE
                  </p>
                  {false ? <><p>(pay the same price no matter how many NFT you mint)</p></> : ""}
                  1 {CONFIG.SYMBOL} NFT costs {CONFIG.DISPLAY_COST}{" "}
                  {CONFIG.NETWORK.SYMBOL}
                  <span style={{ fontSize: "80%" }}> (Excluding gas fees)</span>
                </s.TextTitle>
                <s.SpacerSmall />
                {blockchain.account === "" ||
                  blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      Connect to the {CONFIG.NETWORK.NAME} network
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT
                    </StyledButton>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}

                    <s.SpacerMedium />

                  </s.Container>
                ) : (
                  <>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {feedback}
                    </s.TextDescription>

                    <s.SpacerMedium />

                    {data.freeMintable != undefined ?

                      <>
                        <ContainerMintSection onLoad={setupFixedAmount()}>
                          {//data.freeMintable > 0
                            true
                              ? <s.Container style={{ textAlign: "center" }}>
                                <RolesContainer>
                                  {data.isOG
                                    ? <RolesImgSmall alt={"example"} src={"/config/images/og-role_label.png"} style={{ margin: "auto" }} />
                                    : data.isWL
                                      ? <RolesImgSmall alt={"example"} src={"/config/images/whitelist_label.png"} style={{ margin: "auto" }} />
                                      : data.isWHALE
                                        ? <s.TextTitle
                                          style={{ margin: "auto", fontSize: "2em", fontWeight: "800", textAlign: "center", color: "var(--accent-text)" }}>
                                          WHALE ROLE
                                        </s.TextTitle>
                                        : <RolesImgSmall alt={"example"} src={"/config/images/public_label.png"} style={{ margin: "auto" }} />
                                  }
                                </RolesContainer>
                                <s.TextTitle
                                  style={{ margin: "auto", fontSize: "2em", fontWeight: "800", textAlign: "center", color: "var(--accent-text)" }}
                                >
                                  {(data.isOG || data.isWL || data.isWHALE) && data.freeMintable > 0 ? data.freeMintable + " FREE" : ""}
                                </s.TextTitle>
                                <s.SpacerMedium />
                              </s.Container>
                              : ""}

                          {!claimingNft ?
                            <>
                              <s.Container>
                                <s.Container ai={"center"} jc={"center"} fd={"row"}>
                                  <StyledRoundButton
                                    style={{ lineHeight: 0.4 }}
                                    disabled={claimingNft ? 1 : 0}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      decrementMintAmount();
                                    }}
                                  >
                                    -
                                  </StyledRoundButton>
                                  <s.SpacerMedium />
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    {mintAmount}
                                  </s.TextDescription>
                                  <s.SpacerMedium />
                                  <StyledRoundButton
                                    disabled={claimingNft ? 1 : 0}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      incrementMintAmount();
                                    }}
                                  >
                                    +
                                  </StyledRoundButton>
                                </s.Container>

                                <s.SpacerSmall />
                                <s.Container ai={"center"} jc={"center"} fd={"row"}>
                                  <StyledButton
                                    disabled={claimingNft ? 1 : 0}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      claimNFTs();
                                      getData();
                                    }}
                                  >
                                    {claimingNft ? "BUSY" : "BUY"}{data.hexProofOG != null}
                                  </StyledButton>
                                </s.Container>
                              </s.Container>
                            </>
                            :
                            <s.Container ai={"center"} jc={"center"} fd={"row"}>
                              <SpinnerCircular />
                            </s.Container>}

                          <>
                            <s.SpacerSmall />
                            <s.TextTitle style={{ fontSize: "16px", textAlign: "center", color: "var(--accent-text)" }} >
                              TOTAL COST: {
                                Web3.utils.fromWei(
                                  (mintAmount > data.freeMintable ? ((mintAmount - data.freeMintable) * data.cost) : 0).toString(), 'ether')
                              }
                            </s.TextTitle>
                            <s.TextTitle style={{ fontSize: "16px", textAlign: "center", color: "var(--accent-text)" }} >
                              MAX 20 PER WALLET (0.003 ETH x NFT)
                            </s.TextTitle>
                          </>

                        </ContainerMintSection>
                      </>
                      : <SpinnerCircular />}
                  </>
                )}
              </>
            )}
          </s.Container>

          <s.SpacerMedium />
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg
              alt={"example"}
              src={"/config/images/example2.gif"}
              style={{ transform: "scaleX(-1)" }}
            />
          </s.Container>
        </ResponsiveWrapper>

        <s.SpacerLarge />
        <s.Container jc={"center"} ai={"center"} style={{ textDecoration: "none", textAlign: "center", display: "inline", width: "70%" }}>
          <a className="socialIconLink" href="https://collagebots.com/" style={{ fontSize: "30px" }}>
            <FaHome />
          </a>
          <a className="socialIconLink" href="https://twitter.com/CollageBotsNFT" style={{ fontSize: "30px", marginLeft: "8px" }}>
            <FaTwitter />
          </a>
          <a className="socialIconLink" href="https://discord.gg/UFEkYyHJGY" style={{ fontSize: "30px", marginLeft: "8px" }}>
            <FaDiscord />
          </a>
          <a className="socialIconLink" href={CONFIG.MARKETPLACE_LINK} style={{ fontSize: "30px", marginLeft: "8px" }}>
            <StyledImgIcons
              onMouseOver={(x) => x.target.src = BASE_URL + "config/images/opensea-focus.png"}
              onMouseOut={(x) => x.target.src = BASE_URL + "config/images/opensea.png"}
              alt={""}
              src={"/config/images/opensea.png"}
              style={{ width: "30px", height: "30px" }}
            />
          </a>
          <a className="socialIconLink" href={CONFIG.SCAN_LINK} style={{ fontSize: "30px", marginLeft: "8px" }}>
            <StyledImgIcons
              onMouseOver={(x) => x.target.src = BASE_URL + "config/images/etherscan-focus.png"}
              onMouseOut={(x) => x.target.src = BASE_URL + "config/images/etherscan.png"}
              alt={""}
              src={"/config/images/etherscan.png"}
              style={{ width: "30px", height: "30px" }}
            />
          </a>
        </s.Container>

        <s.SpacerXSmall />
        <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>
          <s.TextDescription
            style={{
              lineHeight: "110%",
              textAlign: "center",
              color: "var(--primary-text)",
            }}
          >
            Please make sure you are connected to the right network (
            {CONFIG.NETWORK.NAME} Mainnet) and the correct address. <s.SpacerXSmall />Please note:
            Once you make the purchase, you cannot undo this action.
          </s.TextDescription>
          <s.SpacerSmall />
          <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--primary-text)",
            }}
          >
            © 2022 Collage Bots All Rights Reserved
          </s.TextDescription>
        </s.Container>
      </s.Container >
    </s.Screen >
  );
}

export default App;
